<div class="results-page-container">
  <div class="full-width-override orient-blue-background">
    <div class="results-page-top-container orient-app-container-wrapper u-pb1">
      <h1 class="page-title vdab-text-color-white u-mb025 hide-md-and-down">
        {{ tempResultsTitleDesktop$ | async }}
      </h1>
      <h1 class="page-title vdab-text-color-white u-mb025 hide-l-and-up">
        {{ tempResultsTitleMobile$ | async }}
      </h1>
      <button class="c-btn -secundary -small hide-l-and-up save-results-btn" (click)="showSaveResultsModal = true">
        <span class="mdi mdi-download"></span>
        {{ tcs.getText("save_results") }}
      </button>
    </div>
  </div>
  <div class="">
    <button class="c-search-filter -mobile result-filter hide-l-and-up" (click)="toggleShowMobileFilterModal()">
      <div class="c-search-filter-container -collapsed" [attr.aria-label]="filterYourResultsLabel">
        <div class="c-search-filter__summary">
          <p class="u-mb0">
            <ng-container *ngVar="activeFiltersCount$ | async as activeFiltersCount">
              <span *ngIf="activeFiltersCount === 0" class="h5 u-display-block">{{ filterLabel }}</span>
              <span *ngIf="activeFiltersCount > 0" class="h5 u-display-block">{{
                formattedActiveFiltersCount$ | async
                }}</span>
            </ng-container>
          </p>
        </div>
        <span class="action-lone mdi mdi-filter mobile-filter-icon" style="font-size: 1.5rem">{{
          filterYourResultsLabel
          }}</span>
      </div>
    </button>
    <div class="results-container u-pt15">
      <div class="result-filter-container">
        <app-result-page-filter [(showMobileFilterModal)]="showMobileFilterModal"></app-result-page-filter>
      </div>
      <div>
        <app-save-results-modal
          class="save-results-modal"
          [(open)]="showSaveResultsModal"
          (openChange)="toggleBlockScrollOnBody($event)"
        ></app-save-results-modal>
        <div class="result-cards-container u-mb15">
          <ng-container *ngIf="suggestedProfessions$ | async as suggestedProfessions">
            <app-result-card
              class="u-display-block"
              *ngFor="let suggestedProfession of suggestedProfessions | slice:0:30; trackBy: trackBySuggestedProfession"
              [suggestedProfession]="suggestedProfession"
              [showExplanationModal]="showExplanationModal"
              (suggestedProfessionClicked)="openExplanationModal($event)"
            ></app-result-card>
            <div class="no-results-container" *ngIf="suggestedProfessions.length === 0">
              <span class="u-text-align-center u-my15">{{ noResultForFilterCriteriaLabel }}</span>
              <vdab-ai-button (click)="navigateToHome()">Doe de test opnieuw</vdab-ai-button>
            </div>
          </ng-container>
        </div>
        <div>
          <p>Niet gevonden wat je zocht? <a href="https://www.vdab.be/orienteren/beroepen/" target="_blank">Ontdek hier alle beroepen.</a></p>
        </div>
      </div>
    </div>
  </div>
</div>
<vdab-ai-dialog-box *ngIf="error$ | async" appearance="warning">
  <span title>{{ tcs.getText("something_went_wrong_general_message") }}</span>
  <span content
  >{{ tcs.getText("something_went_wrong_general_message_content_part_1") }}
    <a href="/">{{ tcs.getText("something_went_wrong_general_message_content_part_2") }}</a>
    {{ tcs.getText("something_went_wrong_general_message_content_part_3") }}
    <a [href]="environment.WELKE_JOB_PAST_BIJ_ME_URL">{{
      tcs.getText("something_went_wrong_general_message_content_part_4")
      }}</a>
  </span>
</vdab-ai-dialog-box>
<ng-container *ngIf="(selectedSuggestedProfession$ | async) as selectedSuggestedProfession">
  <app-suggested-profession-explanation-modal
    [(showModal)]="showExplanationModal"
    [selectedSuggestedProfession]="selectedSuggestedProfession"
  >
  </app-suggested-profession-explanation-modal>
</ng-container>
