import { Component } from "@angular/core";
import { fromEvent, mergeWith, Observable, shareReplay, startWith, Subject, takeUntil } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

import { IEnvironment } from "../../../../environments/IEnvironment";
import { StyleService } from "../../../services/style.service";
import { TextContentService } from "../../../services/text-content.service";
import {ResultFacade} from '../../../state/Result/Result.facade';
import {QuestionFacade} from '../../../state/Question/Question.facade';
import {Empty} from '../../../models/Empty';
import {AnswerInteractionTypes} from '../../../models/Enums';
import {LoadNextQuestionInfoAction} from '../../../state/Question/Question.actions';
import {Store} from '@ngxs/store';
import {ResultSelectors} from '../../../state/Result/Result.selectors';

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent {
  // Data

  public isDesktopViewport$: Observable<boolean>;
  public afterViewInit$: Subject<void> = new Subject<void>();
  public destroy$: Subject<void> = new Subject<void>();

  // Lifecycle

  constructor(public tcs: TextContentService, public styleService: StyleService, public resultFacade: ResultFacade, public questionFacade: QuestionFacade, public store: Store) {
    this.isDesktopViewport$ = this.createIsDesktopViewportHandler();

  }

  startTest() {
    this.resultFacade.startSession();
    this.questionFacade.resetQuestionState()
    const answer = Empty.answer();
    answer.interactionType = AnswerInteractionTypes.Start;
    this.questionFacade.answerQuestion(answer);
    const session = this.store.selectSnapshot(ResultSelectors.session)
    this.store.dispatch(new LoadNextQuestionInfoAction(session));
  }

  // Interface methods
  // Getters & setters

  public get environment(): IEnvironment {
    return environment;
  }

  // Helper methods

  private createIsDesktopViewportHandler(): Observable<boolean> {
    const checkViewport = () => {
      const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      return vw >= this.styleService.styles.medium_screen_bp;
    };
    const checkViewportOnCreate = this.afterViewInit$.pipe(map(() => checkViewport()));
    return fromEvent(window, "resize").pipe(
      takeUntil(this.destroy$),
      map((event: Event) => checkViewport()),
      mergeWith(checkViewportOnCreate),
      startWith(checkViewport()),
      shareReplay(1)
    );
  }
}
