import {Injectable} from "@angular/core";
import {Action, State, StateContext, Store} from "@ngxs/store";
import {catchError, from, Observable, switchMap, tap, throwError} from "rxjs";
import {filter, map} from "rxjs/operators";
import {environment} from "../../../environments/environment";
import {imgReaderFromBlob} from "../../../utils/ImgReader";
import {isNullOrUndefined} from "../../../utils/object";
import {getValueStream} from "../../../utils/with-status";
import {Empty} from "../../models/Empty";
import {
  isBackAnswerInteractionType,
  isMoveForwardKindAnswerInteractionType,
  Loading,
  LoadingEnum,
} from "../../models/Enums";
import {IAnswer} from "../../models/IAnswer";
import {INextQuestion, isProfessionQuestion} from "../../models/INextQuestion";
import {isProfessionQuestionSummary} from "../../models/INextQuestionSummary";
import {convert} from "../../models/mapper";
import {HttpService} from "../../services/http.service";
import {
  AnswerQuestionAction,
  LoadNextQuestionImgAction,
  LoadNextQuestionInfoAction, ResetQuestionState,
  SetShowDescriptionAction,
} from "./Question.actions";

export interface IQuestionState {
  nextQuestion: INextQuestion;
  infoGainProgress: number;
  showDescription: boolean;
  answers: IAnswer[];
  nextQuestionInfoLoading: Loading;
  nextQuestionImgLoading: Loading;
  nextQuestionImgUrl: string;
}

const defaultQuestionState: IQuestionState = {
  nextQuestion: Empty.nextQuestion(),
  infoGainProgress: 0,
  showDescription: false,
  answers: [],
  nextQuestionInfoLoading: LoadingEnum.Success,
  nextQuestionImgLoading: LoadingEnum.Success,
  nextQuestionImgUrl: "abc",
};

@State<IQuestionState>({
  name: "QuestionState",
  defaults: defaultQuestionState,
})
@Injectable()
export class QuestionState {
  // Data

  // Lifecycle

  constructor(private store: Store, private httpService: HttpService) {}

  @Action(ResetQuestionState)
  resetQuestionState(ctx: StateContext<IQuestionState>) {
    ctx.setState(defaultQuestionState);
  }

  @Action(AnswerQuestionAction)
  addAnswer(ctx: StateContext<IQuestionState>, action: AnswerQuestionAction) {
    const answers = ctx.getState().answers;
    let newAnswers: IAnswer[] = [];

    const interactionType = action.answer.interactionType;
    const lastQuestionId = answers.length === 0 ? null : answers[answers.length - 1].currentQuestion.id;

    if (isBackAnswerInteractionType(interactionType)) {
      const questionAlreadyAnswered =
        !isNullOrUndefined(lastQuestionId) && action.answer.currentQuestion.id !== lastQuestionId;

      newAnswers = questionAlreadyAnswered ? answers.slice(0, answers.length - 1) : answers;
    } else if (isMoveForwardKindAnswerInteractionType(interactionType)) {
      const questionAlreadyAnswered =
        !isNullOrUndefined(lastQuestionId) && action.answer.currentQuestion.id === lastQuestionId;
      newAnswers = questionAlreadyAnswered ? answers : [...answers, action.answer];
    } else {
      // console.log("Unknown interaction type: ", interactionType);
      // this.loadNextQuestionImg(ctx, new LoadNextQuestionImgAction());
      return;
    }

    ctx.patchState({ answers: newAnswers });
  }

  @Action(LoadNextQuestionInfoAction)
  loadNextQuestion(ctx: StateContext<IQuestionState>, action: LoadNextQuestionInfoAction): Observable<INextQuestion> {
    ctx.patchState({ nextQuestionInfoLoading: LoadingEnum.Loading, nextQuestionImgLoading: LoadingEnum.Loading });

    return this.httpService.getNextQuestion(action.session).pipe(
      tap((nextQuestion: INextQuestion) => {
        ctx.patchState({
          nextQuestion: nextQuestion,
          nextQuestionInfoLoading: LoadingEnum.Success,
          infoGainProgress: nextQuestion.informationGainProgress,
        });
        this.store.dispatch(new LoadNextQuestionImgAction());
      }),
      catchError((err: Observable<INextQuestion>) => {
        ctx.patchState({ nextQuestionInfoLoading: LoadingEnum.Error });
        console.error("Error while loading next question: ", err);
        return throwError(err);
      })
    );
  }

  @Action(LoadNextQuestionImgAction)
  loadNextQuestionImg(ctx: StateContext<IQuestionState>, action: LoadNextQuestionImgAction) {
    ctx.patchState({ nextQuestionImgLoading: LoadingEnum.Loading });
    // const backupImgUrl$ = this.configService.config$.pipe(
    //   map((config: IConfiguration) => `${config.questionImgPath}/professions_blue.jpg`),
    //   shareReplay(1)
    // );
    // const imgUrl$ = combineLatest([this.configService.config$, backupImgUrl$]).pipe(
    //   map(([config, defaultImgUrl]) => {
    //     const nextQuestion = convert.INextQuestion.ICurrentQuestion(ctx.getState().nextQuestion);
    //     if (isProfessionQuestionSummary(nextQuestion)) {
    //       return defaultImgUrl;
    //     }
    //     return `${config.questionImgPath}/${nextQuestion.id}.jpg`;
    //   })
    // );
    const backupImgUrl = `${environment.QUESTION_IMAGE_PATH}/professions_blue.jpg`;

    const nextQuestion = convert.INextQuestion.ICurrentQuestion(ctx.getState().nextQuestion);
    let imgUrl = "";
    if (isProfessionQuestionSummary(nextQuestion)) {
      imgUrl = backupImgUrl;
    } else {
      imgUrl = `${environment.QUESTION_IMAGE_PATH}/${nextQuestion.id}.jpg`;
    }

    // First try img based on ID
    // If img is not found, use backup url
    return this.httpService.loadImageAsBlob(imgUrl).pipe(
      catchError((err) => {
        console.debug(err);
        return this.httpService.loadImageAsBlob(backupImgUrl).pipe(
          catchError((err) => {
            ctx.patchState({ nextQuestionImgLoading: LoadingEnum.Error });
            return throwError(err);
          })
        );
      }),
      getValueStream,
      switchMap((blob) => {
        return from(imgReaderFromBlob(blob));
      }),
      filter((imgUrl: string) => !isNullOrUndefined(imgUrl) && imgUrl.length > 0),
      map((imgUrl: string) => `url("${imgUrl}")`),
      tap((imgUrl: string) => {
        ctx.patchState({ nextQuestionImgLoading: LoadingEnum.Success, nextQuestionImgUrl: imgUrl });
      })
    );
  }

  @Action(SetShowDescriptionAction)
  setShowDescription(ctx: StateContext<IQuestionState>, action: SetShowDescriptionAction) {
    if (!isProfessionQuestion(ctx.getState().nextQuestion)) {
      return;
    }
    const oldShowDescription = ctx.getState().showDescription;
    ctx.patchState({
      showDescription: action.toggle ? !oldShowDescription : oldShowDescription,
    });
  }
}
