<ng-container *ngVar="isDesktopViewport$ | async as isDesktopViewport">
  <div class="c-card c-card__block card-height card-container-col u-mt1">
    <div
      class="card-container-row u-pt2 u-pr2 u-pb05 u-pl2"
      [class.u-pt2]="isDesktopViewport"
      [class.u-pl2]="isDesktopViewport"
      [class.u-pr2]="isDesktopViewport"
      [class.u-pb05]="isDesktopViewport"
    >
      <h2 class="intro-title">{{ tcs.getText("app_name") }}</h2>

      <h1 class="slogan u-mb0@screen">
        {{ tcs.getText("copy_introduction_title") }}
      </h1>
      <div class="home-card-content">
        <div class="home-intro-text u-mt1" [class.u-m1]="isDesktopViewport">
          <p>
            {{ tcs.getText("copy_introduction_text1") }}
          </p>
          <p>
            {{ tcs.getText("copy_introduction_text2") }}
          </p>
          <a [href]="environment.PRIVACY_URL" target="_blank" class="vdab-text-color-blue">
            {{ tcs.getText("copy_introduction_text3") }}
          </a>
        </div>
        <div class="home-intro-artwork" [class.u-m1]="isDesktopViewport">
          <div class="huge-circle-desktop orient-blue-background">
            <span class="mdi mdi-information-variant circle-icon-huge"></span>
          </div>
          <div class="big-circle-desktop orient-green-background">
            <span class="mdi mdi-thumb-up circle-icon-big"></span>
          </div>
          <div class="small-circle-desktop orient-orange-background">
            <span class="mdi mdi-thumb-down circle-icon-small"></span>
          </div>
        </div>
      </div>
      <div class="start-test-btn-container u-pb1 u-mt2">
        <button class="c-btn -primary" (click)="startTest()" routerLink="vragen">
          {{ tcs.getText("start_test") }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
