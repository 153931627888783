import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import isEqual from "lodash.isequal";
import { combineLatest, distinctUntilChanged, map, Observable, shareReplay, switchMap, withLatestFrom } from "rxjs";

import { objLength } from "../../../utils/object";
import { Empty } from "../../models/Empty";
import {
  AnswerInteractionTypes,
  isBackAnswerInteractionType,
  isDislikeAnswerInteractionType,
  isError,
  isInterestQuestionType,
  isLikeAnswerInteractionType,
  isLoading,
  QuestionTypesEnum,
} from "../../models/Enums";
import { IAnswer } from "../../models/IAnswer";
import { INextQuestion, isEndQuestion } from "../../models/INextQuestion";
import {
  INextQuestionSummary,
  isEndQuestionSummary,
  isProfessionQuestionSummary,
} from "../../models/INextQuestionSummary";
import { IQuestionHistoryLog } from "../../models/IQuestionHistoryLog";
import { IRatedAnswers } from "../../models/IRatedAnswers";
import { ResultFacade } from "../Result/Result.facade";
import {
  AnswerQuestionAction,
  LoadNextQuestionImgAction,
  LoadNextQuestionInfoAction, ResetQuestionState,
  SetShowDescriptionAction,
} from "./Question.actions";
import { QuestionSelectors } from "./Question.selectors";

@Injectable({
  providedIn: "root",
})
export class QuestionFacade {
  // ===================================================================================================================
  // Data

  public nextQuestion$: Observable<INextQuestion>;
  public nextQuestionImgUrl$: Observable<string>;
  public answers$: Observable<IAnswer[]>;
  public questionHistory$: Observable<IQuestionHistoryLog[]>;
  public loading$: Observable<boolean>;
  public error$: Observable<boolean>;
  public lastAnswer$: Observable<IAnswer | null>;
  public nextQuestionSummary$: Observable<INextQuestionSummary>;
  public infoGainProgress$: Observable<number>;
  public showDescription$: Observable<boolean>;

  public answeredInterestQuestions$: Observable<IRatedAnswers>;
  public answeredOccupationalProfileQuestions$: Observable<IRatedAnswers>;

  public showProfessionWarning$: Observable<boolean>;
  public showEndMessage$: Observable<boolean>;

  // ===================================================================================================================
  // Lifecycle

  constructor(private store: Store, private resultFacade: ResultFacade) {
    // Selectors

    this.nextQuestion$ = this.store.select(QuestionSelectors.nextQuestion);
    this.answers$ = this.store.select(QuestionSelectors.answers);
    this.questionHistory$ = this.store.select(QuestionSelectors.questionHistoryLogs);
    this.loading$ = combineLatest([
      this.store.select(QuestionSelectors.nextQuestionInfoLoading),
      this.store.select(QuestionSelectors.nextQuestionImgLoading),
    ]).pipe(
      map(
        ([infoLoading, imgLoading]) =>
          (isLoading(infoLoading) || isLoading(imgLoading)) && !(isError(infoLoading) || isError(imgLoading))
      ),
      shareReplay(1)
    );
    this.error$ = combineLatest([
      this.store.select(QuestionSelectors.nextQuestionInfoLoading),
      this.store.select(QuestionSelectors.nextQuestionImgLoading),
    ]).pipe(
      map(([infoLoading, imgLoading]) => isError(infoLoading) || isError(imgLoading)),
      shareReplay(1)
    );
    this.nextQuestionImgUrl$ = this.store.select(QuestionSelectors.nextQuestionImgUrl);
    this.lastAnswer$ = this.store.select(QuestionSelectors.lastAnswer);
    this.nextQuestionSummary$ = this.store.select(QuestionSelectors.currentQuestion);
    this.infoGainProgress$ = this.store.select(QuestionSelectors.infoGainProgress);
    this.showDescription$ = this.store.select(QuestionSelectors.showDescription);
    this.answeredInterestQuestions$ = this.store.select(QuestionSelectors.answeredInterestQuestions);
    this.answeredOccupationalProfileQuestions$ = this.store.select(
      QuestionSelectors.answeredOccupationalProfileQuestions
    );

    this.showProfessionWarning$ = this.showProfessionWarningHandler();
    this.showEndMessage$ = this.showEndMessageHandler();

    // Effects

    // Update store whenever an interest question is answered
    const answeredInterestQuestionsEffect$ = this.answeredInterestQuestions$.pipe(
      distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
      switchMap((answeredInterestQuestions) => {
        return resultFacade.setInterestQuestions(answeredInterestQuestions);
      }),
      shareReplay(1)
    );

    // Update store whenever a profession question is answered
    const answeredOccupationalProfileQuestionsEffect$ = this.answeredOccupationalProfileQuestions$.pipe(
      distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
      switchMap((answeredOccupationalProfileQuestions) => {
        return resultFacade.setOccupationalProfileQuestions(answeredOccupationalProfileQuestions);
      }),
      shareReplay(1)
    );

    // Load next question, whenever the question is answered
    combineLatest(answeredInterestQuestionsEffect$, answeredOccupationalProfileQuestionsEffect$)
      .pipe(withLatestFrom(this.resultFacade.session$))
      .subscribe(([[,], session]) => {
        this.store.dispatch(new LoadNextQuestionInfoAction(session));
      });

    this.nextQuestion$.subscribe((nextQuestion: INextQuestion) => {
      if (isEndQuestion(nextQuestion)) {
        this.toggleDescription(false);
        // Update session with suggested occupational profiles
        this.resultFacade.setSuggestedOccupationalProfiles(nextQuestion.questionInfo.suggestedOccupationalProfiles);
      }
    });
  }

  // ===================================================================================================================
  // Main methods

  answerQuestion(answer: IAnswer) {
    this.store.dispatch(new AnswerQuestionAction(answer));
  }

  loadNextQuestionInfo(questionType: QuestionTypesEnum, interactionType: AnswerInteractionTypes) {
    const answer: IAnswer = Empty.answer();
    answer.currentQuestion.questionType = questionType;
    answer.interactionType = interactionType;
    this.store.dispatch(new AnswerQuestionAction(answer));
  }

  loadNextQuestionImg() {
    this.store.dispatch(new LoadNextQuestionImgAction());
  }

  goBack() {
    const answer: IAnswer = Empty.answer();
    answer.currentQuestion.questionType = QuestionTypesEnum.ProfessionQuestion;
    answer.interactionType = AnswerInteractionTypes.Back;
    this.store.dispatch(new AnswerQuestionAction(answer));
  }

  toggleDescription(toggle: boolean) {
    this.store.dispatch(new SetShowDescriptionAction(toggle));
  }

  resetQuestionState() {
    this.store.dispatch(new ResetQuestionState());
  }

  // ===================================================================================================================
  // Helper methods

  private showProfessionWarningHandler(): Observable<boolean> {
    return combineLatest(this.nextQuestionSummary$, this.lastAnswer$).pipe(
      withLatestFrom(combineLatest([this.answeredOccupationalProfileQuestions$, this.questionHistory$])),
      map(([[currentQuestion, answer], [ratedProfessions, questionHistory]]) => {
        const lastQuestionFromHistory =
          questionHistory.length === 0 ? null : questionHistory[questionHistory.length - 1];

        const showProfessionWarningWhenGoingForward =
          isProfessionQuestionSummary(currentQuestion) &&
          objLength(ratedProfessions.answers) === 0 &&
          (isLikeAnswerInteractionType(answer?.interactionType) ||
            isDislikeAnswerInteractionType(answer?.interactionType));
        const showProfessionWarningWhenGoingBack =
          isProfessionQuestionSummary(currentQuestion) &&
          objLength(ratedProfessions.answers) === 0 &&
          isInterestQuestionType(lastQuestionFromHistory?.questionType) &&
          isBackAnswerInteractionType(answer?.interactionType);

        return showProfessionWarningWhenGoingForward || showProfessionWarningWhenGoingBack;
      }),
      shareReplay(1)
    );
  }

  private showEndMessageHandler(): Observable<boolean> {
    return this.nextQuestionSummary$.pipe(
      distinctUntilChanged(),
      map((currentQuestion) => isEndQuestionSummary(currentQuestion)),
      shareReplay(1)
    );
  }
}
