import {HttpClientModule} from "@angular/common/http";
import {NgModule} from "@angular/core";
import {ReactiveFormsModule} from "@angular/forms";
import {BrowserModule} from "@angular/platform-browser";
import {NgxsReduxDevtoolsPluginModule} from "@ngxs/devtools-plugin";
import {NgxsModule} from "@ngxs/store";
import {AngularFrontendComponentsModule} from "angular-frontend-components";

import {environment} from "../environments/environment";
import {AppComponent} from "./app.component";
import {AppRoutingModule} from "./app-routing.module";
import {CardReferrerComponent} from "./components/orient/card-referrer/card-referrer.component";
import {MessageCardComponent} from "./components/orient/message-card/message-card.component";
import {QuestionCardComponent} from "./components/orient/question-card/question-card.component";
import {QuestionCardImageComponent} from "./components/orient/question-card-image/question-card-image.component";
import {ResultCardComponent} from "./components/orient/result-card/result-card.component";
import {ResultPageFilterComponent} from "./components/orient/result-page-filter/result-page-filter.component";
import {
  ResultPageFilterHelperComponent
} from "./components/orient/result-page-filter-helper/result-page-filter-helper.component";
import {SaveResultsModalComponent} from "./components/orient/save-results-modal/save-results-modal.component";
import {
  SaveResultsModalFormComponent
} from "./components/orient/save-results-modal-form/save-results-modal-form.component";
import {
  SuggestedProfessionExplanationModalComponent
} from "./components/orient/suggested-profession-explanation-modal/suggested-profession-explanation-modal.component";
import {VdabToolbarComponent} from "./components/orient/vdab-toolbar/vdab-toolbar.component";
import {HomeComponent} from "./components/views/home/home.component";
import {OrientTestPageComponent} from "./components/views/orient-test-page/orient-test-page.component";
import {ResultsPageComponent} from "./components/views/results-page/results-page.component";
import {QuestionState} from "./state/Question/Question.state";
import {ResultState} from "./state/Result/Result.state";
import {SortQuestionsByImpactPipe} from './pipes/sort-questions-by-impact.pipe';
import {FooterComponent} from './components/footer/footer.component';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    VdabToolbarComponent,
    CardReferrerComponent,
    OrientTestPageComponent,
    QuestionCardComponent,
    QuestionCardImageComponent,
    MessageCardComponent,
    ResultsPageComponent,
    ResultCardComponent,
    SuggestedProfessionExplanationModalComponent,
    ResultPageFilterComponent,
    ResultPageFilterHelperComponent,
    SaveResultsModalComponent,
    ResultCardComponent,
    QuestionCardImageComponent,
    SaveResultsModalFormComponent,
    SortQuestionsByImpactPipe,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxsModule.forRoot([QuestionState, ResultState], {
      developmentMode: !environment.production,
      selectorOptions: {
        suppressErrors: false,
        injectContainerState: false,
      },
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
    }),
    NgxsStoragePluginModule.forRoot({
      migrations: [
        {
          // @ts-ignore
          version: undefined,
          migrate: state => {
            return {
              ...state,
              version: 1,
            };
          },
        },
      ],
    }),
    AngularFrontendComponentsModule,
    ReactiveFormsModule,
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
